<template>
   <div class="home">
      <el-table :data="dataList"  border  size="mini" >
         <el-table-column type="index" label="序号"  align="center" width="50px"  />
         <el-table-column prop="pool_number" label="流量池编号" width="auto" align="center" min-width="200px" />
         <el-table-column prop="isp" label="运营商" width="auto" align="center" min-width="100px" />
         <el-table-column prop="spec" label="流量池规格" width="auto" align="center" min-width="100px" />
         <el-table-column prop="pool_total" label="流量池总量" width="auto" :formatter="showDataPoll" align="center" min-width="100px" />
         <el-table-column prop="pool_used" label="已经使用量" width="auto" :formatter="showDataPollSmall" align="center" min-width="100px" />
         <el-table-column prop="pool_remain" label="剩余流量" width="auto" :formatter="showDataPollSmall" align="center" min-width="90px" />
         <el-table-column prop="use_percent" label="流量使用占比" width="auto" :formatter="showDataPollPercent" align="center" min-width="120px" />
         <el-table-column prop="card_total" label="流量总卡数" width="auto"  align="center" min-width="100px" />
         <el-table-column prop="card_total" label="已激活/库存/已停卡/已销卡" :formatter="cardFormatter" width="auto"  align="center" min-width="200px" />
         <el-table-column prop="renew_time" label="最近同步时间" width="auto" :formatter="timeFormat"  align="center" min-width="120px" />
      </el-table>
      <div class="bottom">
         <el-pagination background layout="prev,sizes, pager, next" :total="toaltal"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
         </el-pagination>
      </div>
   </div>
</template>

<script>
export default {
  name: "directFlowPool",
   data:function () {
      return{
         dataList:[],
         toaltal:0,
         pageNum:1,
         pageSize:10,
      }
   },
   components: {},
   mounted() {
      this.getCardPools();
   },
   methods:{
     //获取流量池列表
      getCardPools:function () {
         let account=this.$commonUtils.getSessionData(this.$constant.account)
         let params={
            pageNum:this.pageNum,
            pageSize:this.pageSize,
            account:account,
         }
         let that=this
         this.$getRequest(this.$urlUtils.cardGetListCardPools,params).then(res => {
            that.dataList=res.data.list
            that.toaltal=res.data.total
            console.log('getCardPools',that.toaltal,res.data)
         })
      },
      //分页回调
      handleSizeChange:function(number){
         console.log('number1',number)
         this.pageSize=number
         this.getCardPools()
      },
      //分页回调
      handleCurrentChange:function(number){
         console.log('number2',number)
         this.pageNum=number
         this.getCardPools()
      },
      //格式化流量池数据
      showDataPoll:function(row){
         if(row.pool_total!==null&&row.pool_total!=='null'){
            return row.pool_total+"G";
         }else{
            return "0G";
         }
      },
      //格式化流量池 保留三位小数点
      showDataPollSmall:function (row,column) {
         console.log('row',row,'column',column.property)
         if(row[column.property]!==null&&row[column.property]!=='null'){
            return row[column.property].toFixed(3)+"G";
         }else{
            return "0G";
         }
      },
      //流量使用占比
      showDataPollPercent:function (row) {
         let data = row.use_percent;
         if(data!==null&&data!=='null'){
            return (parseFloat(data)*100).toFixed(2)+"%";
         }else{
            return "0.00%";
         }
      },
      //时间格式化
      timeFormat:function (row) {
         let data = row.renew_time;
         if(data!==null&&data!=='null'&&data.length>=10){
            return row.renew_time.substring(0,10);
         }else{
            return "0.00%";
         }
      },
      //卡格式化
      cardFormatter:function (row) {
         let card_activited = row.card_activited==null||row.card_activited===''?0:row.card_activited ;
         let card_store = row.card_store==null||row.card_store===''?0:row.card_store ;
         let card_halt = row.card_halt==null||row.card_halt===''?0:row.card_halt ; //已停卡
         let card_logout = row.card_logout==null||row.card_logout===''?0:row.card_logout ; //已销卡
         return card_activited+'/'+card_store+'/'+card_halt+'/'+card_logout
      }
   }
}
</script>

<style scoped>
   .home{
      padding: 10px;
   }
   .bottom{
      width: 100%;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 50px;
   }

</style>